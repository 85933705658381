import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

import wallet from "../../assets/images/wallet.png";
import walletGoogle from "../../assets/images/g-wallet.png";
import InputMask from "react-input-mask";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { appleWalletAPI } from "../../reducers/appleWallet";
import { setSMSOrEmailResponse } from "../../reducers/sendSMSOrEmail";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { MotionButton, MotionIcon } from "../motion/MotionButton";
import editIcon from "./../../assets/images/RxLink assets/new assets/edit pen.svg"
import { useFormik } from "formik";
import * as Yup from "yup";
import { MotionTextH2 } from "../motion/MotionText";
import { SendSMSOrEmailModalTab } from "./Modals/SendSMSorEmailModal";
import { setShowPahrmacyMap } from "../../reducers/medication";

import bestOffer from "../../assets/images/best-offer.png";
import cheaper from "../../assets/images/cheaper.png";

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { successToast, errorToast } from "../toast/toast";
import { searchPharmacyLogo } from "../PharmacyLogo";
import { saveCareCardInfo, setSaveCareCardInfo } from "../../reducers/careCardInfo";
import { StringToHTML } from "../../utilities/StringToHtml";

export const getCrxBestPrice = (sub_element) => {
  let priceToAdd = 0;
  let priceToMinus = 0;
  sub_element?.drugs?.map((dr) => {
    if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
      priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
      priceToMinus += dr?.bestPrice;
    }
  })
  let newSum = sub_element?.bestPrice - priceToMinus + priceToAdd;
  return newSum?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
}

export const getCard = (med, rxSenseCard, hippoCardInfo, connectiveCards, careCardInfo = {}) => {
  let connectCard = connectiveCards?.filter((crd) => crd?.ndc == med?.selectedDrug?.ndc);
  let card = null;
  if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
    card = {
      pcn: connectCard?.[0]?.pcn,
      bin: connectCard?.[0]?.bin,
      memberId: connectCard?.[0]?.memberId,
      groupId: connectCard?.[0]?.groupId?.split(" ")?.[0]
    }
    return card;
  }
  else {
    card = {
      pcn: med?.rxSense ? rxSenseCard?.pcn : med?.hasInsurance ? med?.InsuranceInfo.pcn : med?.isCareCard ? med?.careCardInfo?.pcn : hippoCardInfo.pcn,
      bin: med?.rxSense ? rxSenseCard?.bin : med?.hasInsurance ? med?.InsuranceInfo.bin : med?.isCareCard ? med?.careCardInfo?.bin : hippoCardInfo.bin,
      memberId: med?.rxSense ? rxSenseCard?.memberId : med?.hasInsurance ? med?.InsuranceInfo.ID :  med?.isCareCard ? med?.careCardInfo?.memberId : hippoCardInfo.member_id,
      groupId: med?.rxSense ? rxSenseCard?.groupId : med?.hasInsurance ? med?.InsuranceInfo.Group :  med?.isCareCard ? med?.careCardInfo?.groupId : hippoCardInfo.group_id,
      hasInsurance: med?.hasInsurance,
      memberInfoSeqNo: med?.rxSense ? rxSenseCard?.memberInfoSeqNo : hippoCardInfo?.memberInfoSeqNo,
      careCardInfoSeqNo: careCardInfo ? careCardInfo?.careCardInfoSeqNo : ''
    }
    return card;
  }
}

export default (props) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isEmailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const [isEmail, setIsEmail] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const memberInfo = useSelector((state) => state.sendSMSOrEmailAPI.memberInfo);
  const connectiveCards = useSelector((state) => state.membersAssignAPI.connectiveCards);
  const careCards = useSelector( (state) => state?.careCardDetails?.careCardSave);
  const [loading, setLoading] = useState(false);
  const [isWalletURL, setWalletURL] = useState("");
  const [pharmacistPara, setPharmacistPara] = useState(false);
  const [isEmailOrSMSSuccess, setIsEmailOrSMSSuccess] = useState(false);
  const [termsSMSorEmail, setTermsSMSorEmail] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [walletList, setWalletList] = useState([]);
  var numberPattern = /\d+/g;

  var patientSeqNo = JSON.parse(localStorage.getItem("patientSeqNo"))


  const patient = props?.patient;
  const tooltipOfferRefCoupon = useRef(null);

  const pharmacyState = JSON.parse(sessionStorage.getItem('selectMapValues'));

  const rxSenseCard = JSON.parse(localStorage.getItem("rxSenseCard"));

  const isUpenn = window.location.href.includes("upenn");
  const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
  const isTripment = window.location.href.includes("tripment");
  const isHouston = window.location.href.includes("hhc");
  const isLcmc = window.location.href.includes("lcmc");

  const healthSystem = isUpenn ? "Upenn Medicine" :
    isPapaJohn ? "Papa John's United" :
      isTripment ? "Tripment Health" :
        isHouston ? "Housten Health System" :
          isLcmc ? "LCMC Health" :
            "RxLink";


  const navigateToCouponSent = () => {
    setIsEmailOrSMSSuccess(true)
  }

  const termsHandler = (e) => {
    setTermsSMSorEmail(e.target.checked)
    if (e.target.checked) {
      setTermsError(false)
    }
  }

  // useEffect(() => {

  //   if (props?.objParam?.drug?.pharmacyName) {
  //     appleWallet();
  //     // debugger;
  //   }
  // }, [props?.objParam?.drug?.pharmacyName]);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailValue(email);
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid email");
    }
  };
  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }

  const sendSMS = (data) => {
    const { lat, lng, postalCode } = props.ownPosition;
    // if (termsSMSorEmail) {
    //   setTermsError(false)
    // } else {
    //   setTermsError(true)
    //   return;
    // }
    if(loading) return;
    if (isSMS) {
      if (data) {
        setLoading(true);

        let body = {
          sendSMS: true,
          mobileNo: data?.username.match(numberPattern).join(""),
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          patientSeqNo: patientSeqNo,
          memberInfo: memberInfo ? memberInfo : null,
          pharmacyName: props?.objParam?.drug?.pharmacyName,
          longitude: pharmacyState?.lng || props?.ownPosition?.lng,
          latitude: pharmacyState?.lat || props?.ownPosition?.lat,
          state: pharmacyState?.state,
          walletHash: patient.walletHash,
          drugs: props?.objParam?.medDrugData?.map((element, index) => {

            let med = props?.drugPrices[index];
            if (med?.chain || med?.bestPrice > 0 || med?.hasInsurance) {
              let names = processInput(element?.selectedDrug?.names);

              const getName = () => {
                if (names.length > 1) {
                  return (
                    names[1] +
                    ` (${element?.selectedDrug?.name})`
                  );
                }
                else if (names.length == 1) {
                  return element?.selectedDrug?.name
                }
              }
              return {
                name: getName(),
                dosage: element?.selectedDrug?.dosage,
                form: element?.selectedDrug?.form,
                default_quantity: element?.selectedDrug?.default_quantity,
                price: med?.selectedDrug?.connectiveRxOffer?.hasCard == 1 ? med?.selectedDrug?.connectiveRxOffer?.price : med?.bestPrice,
                cardInfo: getCard(element, rxSenseCard, props.objParam.memberAssign, connectiveCards, careCards)
                // cardInfo: {
                //   pcn: med?.rxSense ? rxSenseCard?.pcn : med?.hasInsurance ? med?.InsuranceInfo.pcn : props.objParam.memberAssign.pcn,
                //   bin: med?.rxSense ? rxSenseCard?.bin : med?.hasInsurance ? med?.InsuranceInfo.bin : props.objParam.memberAssign.bin,
                //   memberId: med?.rxSense ? rxSenseCard?.memberId : med?.hasInsurance ? med?.InsuranceInfo.ID : props.objParam.memberAssign.member_id,
                //   groupId: med?.rxSense ? rxSenseCard?.groupId : med?.hasInsurance ? med?.InsuranceInfo.Group : props.objParam.memberAssign.group_id,
                //   hasInsurance: med?.hasInsurance,
                // }
              };
            }
          }).filter(Boolean),
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            if (response?.status === "OK") {
              setIsSMS(false);
              navigateToCouponSent();
              setTermsSMSorEmail(false);
              successToast(response?.message);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
              setWalletURL(response?.data?.walletUrl);
            } 
            else if(response?.status === "BANDWIDTH_LIMIT_EXCEEDED"){
              errorToast(<StringToHTML htmlString={"The coupon was already sent to this number. Please wait 10 minutes and if the coupon is still not received, try to use the email or mobile wallet option or email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
            }
            else {
              errorToast(response?.message);
            }
          })
          .catch((e) => { })
          .finally((e) => {
            setLoading(false);
            closeSMSOrEmailPopup(false);
          });
      }
    }

  };

  const sendEmail = (flag) => {

    if (isEmail) {
      if (!isEmailValue) {
        setEmailError("Email is required");
        return;
      }

      if (!isValidEmail) {
        setEmailError("Enter valid email");
        return;
      }
      // if (termsSMSorEmail) {
      //   setTermsError(false)
      // } else {
      //   setTermsError(true)
      //   return;
      // }

      if(loading) return;
      console.log({ props })
      if (flag) {
        setLoading(true);
        let body = {
          sendSMS: false,
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          patientSeqNo: patientSeqNo,
          email: email,
          pharmacyName: props?.objParam?.drug?.pharmacyName,
          price: "$" + getCrxBestPrice(props?.objParam?.drug),
          state: pharmacyState?.state,
          walletHash: patient.walletHash,
          drugs: props?.objParam?.medDrugData?.map((element, index) => {
            let med = props?.drugPrices[index];
            if (med?.chain || med?.bestPrice > 0 || med?.hasInsurance) {
              let names = processInput(element?.selectedDrug?.names);

              const getName = () => {
                if (names.length > 1) {
                  return (
                    names[1] +
                    ` (${element?.selectedDrug?.name})`
                  );
                }
                else if (names.length == 1) {
                  return element?.selectedDrug?.name
                }
              }
              return {
                name: getName(),
                dosage: element?.selectedDrug?.dosage,
                form: element?.selectedDrug?.form,
                default_quantity: element?.selectedDrug?.default_quantity,
                // price: med?.bestPrice,
                price: med?.selectedDrug?.connectiveRxOffer?.hasCard == 1 ? med?.selectedDrug?.connectiveRxOffer?.price : med?.bestPrice,
                cardInfo: getCard(element, rxSenseCard, props.objParam.memberAssign, connectiveCards, careCards)

                // cardInfo: {
                //   pcn: med.rxSense ? rxSenseCard?.pcn : med.hasInsurance ? med.InsuranceInfo.pcn : props.objParam.memberAssign.pcn,
                //   bin: med.rxSense ? rxSenseCard?.bin : med.hasInsurance ? med.InsuranceInfo.bin : props.objParam.memberAssign.bin,
                //   memberId: med.rxSense ? rxSenseCard?.memberId : med.hasInsurance ? med.InsuranceInfo.ID : props.objParam.memberAssign.member_id,
                //   groupId: med.rxSense ? rxSenseCard?.groupId : med.hasInsurance ? med.InsuranceInfo.Group : props.objParam.memberAssign.group_id,
                //   hasInsurance: med.hasInsurance,
                // }
              };
            }
          }).filter(Boolean),
          // memberInfo: props?.objParam?.memberAssign
          //   ? props?.objParam?.memberAssign
          //   : null,
          longitude: pharmacyState?.lng || props?.ownPosition?.lng,
          latitude: pharmacyState?.lat || props?.ownPosition?.lat,
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            setLoading(false);

            if (response?.status === "OK") {
              setIsEmail(false);
              navigateToCouponSent();
              setTermsSMSorEmail(false);
              setEmailValue("");
              successToast(response?.message);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
            } else {
              setEmailValue("");
              errorToast(response?.message);
            }
          })
          .catch((e) => {

          }).finally((e) => {
            closeSMSOrEmailPopup(false);
            setLoading(false);
          });
      }
    }

  };

  // const fetchData = async (parameter) => {
  //   try {
  //     const response = await httpClientJava.post("npiApiResult", {
  //       enumeration_type: "NPI-2",
  //       postal_code: ownPosition.postalCode,
  //       limit: "200",
  //       skip: `${parameter}`,
  //     });

  //     return JSON.parse(response.data.data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     return [];
  //   }
  // };

  // useEffect(() => {
  //   const fetchDataForAllParameters = async () => {
  //     const requests = parameters.map(parameter => fetchData(parameter));
  //     const responseDataArray = await Promise.all(requests);

  //     // Combine the arrays into a single array
  //     const combinedData = responseDataArray.reduce((accumulator, data) => {
  //       return accumulator.concat(data.results);
  //     }, []);

  //     setData(combinedData);
  //     setPostal(ownPosition?.postalCode)
  //     // debugger;
  //   };
  //   if (ownPosition?.postalCode && ownPosition?.postalCode != postal) fetchDataForAllParameters();
  // }, [ownPosition]);


  const appleWallet = () => {
    // const { lat, lng, postalCode } = props.ownPosition;
    if (!props?.objParam?.drug?.pharmacyName) {
      return;
    }
    if (JSON.parse(localStorage.getItem("smsWalletUrl"))) {
      const walletUrl = JSON.parse(localStorage.getItem("smsWalletUrl"));
      setWalletURL(walletUrl);
    }
    else {
      props.objParam.medDrugData.map((element, i) => {
        let med = props?.drugPrices[i];
        let cardInfo = {
          pcn: med?.rxSense ? rxSenseCard?.pcn : med?.hasInsurance ? med?.InsuranceInfo.pcn : props.objParam.memberAssign.pcn,
          bin: med?.rxSense ? rxSenseCard?.bin : med?.hasInsurance ? med?.InsuranceInfo.bin : props.objParam.memberAssign.bin,
          memberId: med?.rxSense ? rxSenseCard?.memberId : med?.hasInsurance ? med?.InsuranceInfo.ID : props.objParam.memberAssign.member_id,
          groupId: med?.rxSense ? rxSenseCard?.groupId : med?.hasInsurance ? med?.InsuranceInfo.Group : props.objParam.memberAssign.group_id,
          hasInsurance: med?.hasInsurance,
        }
        setLoading(true);
        let body = {
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          pharmacyName: props?.objParam?.drug?.pharmacyName,
          price: "$" + props?.objParam?.drug?.bestPrice.toLocaleString(),
          drugs: [
            // props?.objParam?.medications?.map((element, index) => {
            //   let med = props?.drugPrices[index]?.drugPrice;
            //   return 
            {
              name: element?.selectedDrug?.search_name,
              dosage: element?.selectedDrug?.dosage,
              form: element?.selectedDrug?.form,
              default_quantity: element?.selectedDrug?.default_quantity,
              price: med?.bestPrice,

              cardInfo: cardInfo
            }],
          // }),
          memberInfo: cardInfo,
          latitude: props?.objParam?.drug?.lat,
          longitude: props?.objParam?.drug?.lng,
          state: pharmacyState?.state,
        };
        dispatch(appleWalletAPI(body))
          .unwrap()
          .then((response) => {
            setLoading(false);

            if (response?.status === "OK") {
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
              setWalletURL(response?.data?.walletUrl);
              setWalletList(list => [...list, { name: element?.selectedDrug?.search_name, url: response?.data?.walletUrl }]);
            } else {
              errorToast(response?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
          });

      })
    }
  };

  useEffect(() => {
    console.log({ walletList })
  }, [walletList])

  useEffect(() => {
    
    if (props?.show && props?.objParam?.medDrugData?.length > 0) {
      const getUniqueCareCards = (careCardArr, keys) => {
        return careCardArr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            keys.every(key => t[key] === item[key])
          ))
        );
      };
      const uniqueCareCardArr = getUniqueCareCards(props?.objParam?.medDrugData?.filter((x) => { if (x?.isCareCard) { return x; } }), ['bin', 'pcn', 'groupId']);
      if (uniqueCareCardArr?.length > 0) {
        uniqueCareCardArr?.forEach((element, ind) => {
          if (element?.isCareCard) {
            let saveCareCardBody = {
              "appUserSeqNo": JSON.parse(localStorage.getItem("user")).appUserSeqNo,
              "ndc": element.selectedDrug.ndc,
              "price": element?.bestPrice,
              "estRetailPrice": element?.estRetailPrice,
              "quantity": element.selectedDrug.default_quantity,
              "pharmacyName": props?.pharmaAddress?.pharmacyName,
              "pharmacyNpi": props?.pharmaAddress?.npi,
              "zipcode": props?.pharmaAddress?.zipCode ? props?.pharmaAddress?.zipCode : props?.ownPosition?.postalCode,
              "bin": element?.careCardInfo?.bin,
              "pcn": element?.careCardInfo?.pcn,
              "groupId": element?.careCardInfo?.groupId,
              "memberId": element?.careCardInfo?.memberId
            };
            if (saveCareCardBody?.appUserSeqNo) {
              dispatch(saveCareCardInfo(saveCareCardBody))
                .unwrap().then((response) => {
                  if (response?.data) {
                    dispatch(setSaveCareCardInfo(response?.data));
                  }
                })
            }
          }
        })
      }


    }
  }, [props?.show, props?.objParam?.medDrugData])

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Phone number is required").min(10, "Phone number must be 10 digits")
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,

    onSubmit: (data) => {
      sendSMS(data);
    },
  });

  const openSMSpopup = (e) => {
    formik.resetForm();
    setIsSMS(e);
    // formik.values.username = "";

  };

  const closeSMSOrEmailPopup = (e) => {
    setTermsError(false)
    setTermsSMSorEmail(false)
    setIsEmailOrSMSSuccess(e);
    formik.setTouched({}, false);
    setIsSMS(false);
    setIsEmail(false);
  }

  const openEmailPopup = (e) => {
    setIsEmail(e);
    setEmailError("");
  };


  let cheaperOptionAvailable = props?.cheaperOptionAvailable;

  return (
    <>
      <Modal
        show={props.show}
        centered
        keyboard={false}
        dialogClassName="modal-custom-className"
        aria-labelledby="example-custom-modal-styling-title"
        id="example-custom-modal-styling-title"
      >
        <Modal.Body id="example-modal-sizes-title-lg">
          <section className="popupCouponCard">
            <section className="tabCoupon ">
              <div className="">
                <div className="row coupon_main">
                  <div className="col-sm-12 col-md-12 col-lg-12 ">
                    <div className="medications_inner medications_inner_coupon dlr-Sup  medications_inner_imhere ">
                      <div className="row">
                        <div className="col-7 col-sm-7 col-md-9 col-lg-9 ">
                          <label className="">TOTAL OUT OF POCKET </label>

                          {/* <div className="med-Flex">
                            <h1 className="webView">
                              <sup>
                                <b>$&nbsp;</b>
                              </sup>
                              43.49
                            </h1>
                            <h3 className="mobileView">
                              <sup>
                                <b>$&nbsp;</b>
                              </sup>
                              43.49
                            </h3>
                            <p className="font13 flex-end">
                              91
                              %&nbsp;off{" "}
                            </p>
                          </div> */}

                          <div className="med-Flex">
                            <h1 className="webView">
                              <sup>
                                <b>$&nbsp;</b>
                              </sup>
                              {/* {props.objParam?.drug?.bestPrice?.toLocaleString(
                                undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                              )}{" "} */}
                              {getCrxBestPrice(props?.objParam?.drug)}
                            </h1>
                            <h3 className="mobileView">
                              <sup>
                                <b>$&nbsp;</b>
                              </sup>
                              {getCrxBestPrice(props?.objParam?.drug)}

                            </h3>
                            <p className="font13 flex-end">
                              {Math.round(
                                props?.objParam?.savingPercentage
                              )}
                              %&nbsp;off{" "}
                            </p>
                          </div>

                        </div>
                        <div className="col-5 col-sm-5 col-md-3 col-lg-3 autoMargin">
                          <MotionButton>
                            <button
                              className="btn_default"
                              onClick={props?.close}
                              style={{ width: "65%", float: "right" }}
                            >
                              Hide
                            </button>
                          </MotionButton>
                        </div>
                      </div>
                      <div className="row hrDiv g-0">
                        <div className="col-sm-12 col-md-12 col-lg-5">
                          <label className="">PREFERRED PHARMACY</label>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-7 col-lg-7 pr-0">
                                <h4 className="mb-2 webView font-weig-4">
                                  Save your discounts to keep them handy
                                </h4>
                              </div> */}
                      </div>
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-5">
                          <div className="review-coupon  m-0">
                            <div className="row ">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                <div className="pharmacyImg_div">
                                  <img
                                    src={
                                      searchPharmacyLogo(props.objParam?.drug?.pharmacyName, props?.objParam?.drug?.logo?.small)
                                    }
                                    className="img-fluid "
                                  />
                                </div>
                              </div>
                              <div className="col-7 col-sm-7 col-md-7 col-lg-7 autoMargin">
                                <div className="">
                                  <p className="shopName">
                                    {" "}
                                    {props.objParam?.drug?.pharmacyName}<br /> <span style={{ fontWeight: "normal" }}>{props?.pharmaAddress?.address?.split(",")[0]}</span>
                                  </p>
                                </div>
                              </div>
                              {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3 best-cheap-offer" >
                                      <OverlayTrigger
                                        delay={{ hide: 100, show: 100 }}
                                        trigger="hover"
                                        ref={tooltipOfferRefCoupon}
                                        overlay={(props) => (
                                          <Tooltip {...props}>
                                            {cheaperOptionAvailable ? "Cheaper Offer Available" : "Best Offer"}
                                          </Tooltip>
                                        )}
                                        placement="auto"
                                      >
                                        {
                                          cheaperOptionAvailable ? <img src={cheaper} style={{ cursor: "pointer" }} />
                                            : <img
                                              src={bestOffer}
                                              style={{ cursor: "pointer" }}
                                            />
                                        }
                                      </OverlayTrigger>
                                    </div> */}
                              <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-end">
                                <MotionIcon>
                                  <img className="pointer" src={editIcon} onClick={() => dispatch(setShowPahrmacyMap(true))} />
                                </MotionIcon>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-5">
                          <h4 className="mt-2 mb-2 webView font-weig-4">
                            Choose a method to send discounts to patient
                          </h4>
                        </div>
                        <div className="mobileView mt-2">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                              <h4 className="row  mb-2  font-weig-4 ">
                                <b className="font_14_force p-0">
                                  {" "}
                                  Choose a method to send discounts to patient
                                </b>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7 alignSelfEnd">
                          <div className="row mt-1">
                            <div className="col-3 col-sm-4 col-md-4 col-lg-3 autoMargin ">
                              <MotionButton>
                                <button
                                  className="btn_success"
                                  type="button"
                                  onClick={() => openSMSpopup(true)}
                                  style={{ padding: "12px 12px" }}

                                >
                                  SMS
                                </button>
                              </MotionButton>
                            </div>
                            <div className="col-3 col-sm-4 col-md-4 col-lg-3 autoMargin ">
                              <MotionButton>
                                <button
                                  className="btn_success"
                                  type="button"
                                  onClick={() => openEmailPopup(true)}
                                  style={{ padding: "12px 12px" }}
                                >
                                  Email
                                </button>
                              </MotionButton>
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-6" style={{ marginTop: 13 }}>
                              {/* <div >
                                {isIOS && (
                                  <>

                                    <img
                                      src={wallet}
                                      className="img-fluid"
                                    />

                                  </>
                                )}

                                {isAndroid && (
                                  <>

                                    <img
                                      src={walletGoogle}
                                      className="img-fluid"
                                    />

                                  </>
                                )}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="hrDiv mobileView"></div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12">
                          {props.windowWidth < 992 &&
                            <div className="offer-section" style={{ marginTop: -4 }}>
                              {props.cheaperOptionAvailable ?
                                <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-circle-exclamation" style={{ fontSize: 21 }}></i><span style={{ marginLeft: 15 }}>Your price may be lower at <a href="javascript:void(0)" onClick={props.pencilAction}>a different pharmacy.</a></span>
                                </div>
                                :
                                <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-tag" style={{ color: "#40DFB9", fontSize: 26 }}></i><span style={{ marginLeft: 15 }}>Congratulations! Your pharmacy has the lowest total price for your drugs in your area!</span>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </div> */}
                    </div>
                  </div>


                </div>
              </div>
            </section>
          </section>

          <SendSMSOrEmailModalTab
            isOpen={isSMS || isEmail || isEmailOrSMSSuccess}
            formik={formik}
            handleClose={() => closeSMSOrEmailPopup(false)}
            isSMS={isSMS}
            sendEmail={() => sendEmail(true)}
            sendSMS={formik.handleSubmit}
            validateEmail={validateEmail}
            emailError={emailError}
            hasSuccess={isEmailOrSMSSuccess}
            termsHandler={termsHandler}
            termsError={termsError}
          />


        </Modal.Body>
      </Modal>
    </>
  );
};
